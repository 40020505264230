/* src/Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px); /* Adjust based on header height */
}

.class-dropdown {
  margin-bottom: 20px;
  text-align: center;
}

.class-dropdown label {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
}

.class-dropdown select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.google-login-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #4285f4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.google-login-button img {
  width: 20px;
  margin-right: 10px;
}

.google-login-button:disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}
