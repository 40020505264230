/* src/Home.css */
.content {
    padding: 20px;
  }
  
  .class-dropdown {
    margin-bottom: 20px;
  }
  
  .class-dropdown label {
    margin-right: 10px;
  }
  
  .data-display {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .card {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .card-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-content {
    display: none;
  }
  
  .card-content.expanded {
    display: flex;
  }
  
  .left-pane {
    width: 30%;
    border-right: 1px solid #ddd;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .right-pane {
    width: 70%;
    padding-left: 15px;
  }
  
  .left-pane .key-item {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    text-align: left;
    width: 100%;
    transition: background-color 0.3s;
  }
  
  .left-pane .key-item:hover {
    background-color: #e0e0e0;
  }
  
  .left-pane .key-item.active {
    background-color: #dcdcdc;
    font-weight: bold;
  }
  
  .segment-card-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .vertical-table {
    width: 100%;
    border-collapse: collapse;
    word-wrap: break-word;
  }
  
  .vertical-table th,
  .vertical-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .vertical-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .vertical-table td:first-child {
    font-weight: bold;
  }
  
  .error-message {
    color: #d9534f;
    background-color: #f8d7da;
    border: 1px solid #f5c2c7;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
    text-align: center;
  }
  
  .loading {
    text-align: center;
    font-size: 18px;
    color: #333;
  }
  